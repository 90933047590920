import React from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import moment from 'moment';

import { Chatroom, Provider } from '../../typings';
import { isImage, isFile } from '../chat/message-bubble/helpers';

type Props = {
  className?: string;
  chatroom: Chatroom;
  provider: Provider;
};

const ChatroomCell: React.FC<Props> = ({ className, chatroom, provider }) => {
  if (!chatroom.otherParticipant) {
    return null;
  }

  const chatDateFormatter = (chatUpdatedAt) => {
    let formatPattern;

    if (chatUpdatedAt.diff(moment(), 'days') === 0) {
      formatPattern = 'LT';
    } else {
      formatPattern = 'l';
    }

    return chatUpdatedAt.format(formatPattern);
  };

  const { otherParticipant } = chatroom;

  return (
    <Row
      className={`border p-2 ${className || ''}`}
      style={{ borderColor: '#DEDEDE' }}
    >
      <Col lg={2} style={{ display: 'block', margin: 'auto' }}>
        <Image
          src={
            otherParticipant.pictureUri
              ? chatroom.otherParticipant.pictureUri
              : '/assets/profileAvatar.png'
          }
          width="88"
          height="88"
          roundedCircle
        />
      </Col>

      <Col lg={7}>
        {otherParticipant.name ? (
          <p className="mb-1" style={{ fontWeight: 500 }}>
            {otherParticipant.name}
          </p>
        ) : (
          <p className="mb-1" style={{ fontWeight: 500 }}>
            {otherParticipant.firstName} {otherParticipant.lastName}
          </p>
        )}
        {chatroom.lastMessage ? (
          <p className="text-muted">
            {chatroom.lastMessage.sender == provider.id && (
              <span className="im-sender">You: </span>
            )}
            {isImage(chatroom.lastMessage.body)
              ? 'Image 📷'
              : isFile(chatroom.lastMessage.body)
                ? 'Document 📄'
                : chatroom.lastMessage.body}
          </p>
        ) : null}
      </Col>

      <Col lg={2} className="text-end">
        <span
          className="text-muted"
          style={{
            position: 'relative',
            top: 0,
            right: 0,
            alignSelf: 'flex-start',
          }}
        >
          {chatDateFormatter(moment(chatroom.updatedAt))}
        </span>
      </Col>
      <Col lg={1} className="vcenter">
        {chatroom.unreadCount > 0 &&
        chatroom.lastMessage &&
        chatroom.lastMessage.sender === chatroom.otherParticipant.id ? (
          <span className="ml-4 justify-content-center vcenter blue-notification">
            {chatroom.unreadCount}
          </span>
        ) : null}
      </Col>
    </Row>
  );
};

export default ChatroomCell;
