import moment from 'moment';
import { ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import { FormLabel } from 'react-bootstrap';
import { FC, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';

import { FormControlProps } from '../../utils/formControlProps';

type PropTypes = {
  showFieldError?: boolean;
  validate?: any;
  label?: string;
  autocomplete?: string;
  setFieldValue: (key: string, value: Date | string) => void;
} & Omit<FormControlProps, 'label'>;

const MMDDatePickerFly: FC<PropTypes> = ({
  values,
  fieldKey,
  label,
  placeholder,
  setFieldValue,
}) => {
  const [isDefaultValue, setIsDefaultValue] = useState(true);
  const [isInvalidValue, setIsInvalidValue] = useState(false);
  const [stateBirthday, setStateBirthday] = useState('');

  useEffect(() => {
    if (typeof stateBirthday === 'object') {
      setFieldValue(fieldKey, stateBirthday);
    } else if (
      typeof stateBirthday === 'string' &&
      stateBirthday.length === 10 &&
      !stateBirthday.includes('-')
    ) {
      const dateAux = new Date(moment(stateBirthday, 'MM/DD/YYYY').toString());
      setFieldValue(fieldKey, dateAux);
    } else {
      setFieldValue(fieldKey, '');
    }
  }, [stateBirthday]);

  const handleChange = (val) => {
    if (typeof val === 'object') {
      setStateBirthday(val);
      setIsInvalidValue(false);
    } else if (typeof val === 'string') {
      setStateBirthday(val);
      const dateTest = val.replace('-', '/').replace('-', '/');
      const dateTestAux = new Date(dateTest).toString();
      const validDate = moment().subtract(18, 'years').toDate();
      if (
        moment(dateTest, 'MM/DD/YYYY', true).isValid() &&
        Date.parse(validDate.toString()) > Date.parse(dateTestAux)
      ) {
        setIsInvalidValue(false);
      } else {
        setIsInvalidValue(true);
      }
    }
  };

  useEffect(() => {
    if (values[fieldKey]) {
      handleChange(values[fieldKey]);
    }
  }, []);

  return (
    <>
      {label && (
        <FormLabel className="ml-3 form-label" htmlFor={fieldKey}>
          {label}
        </FormLabel>
      )}
      {!label && <div className="spacer-input-base" />}

      {/*// @ts-ignore*/}
      <DatePicker
        className="form-control"
        autoComplete="off"
        name={fieldKey}
        dateFormat="MM-dd-yyyy"
        selected={values[fieldKey] && new Date(values[fieldKey])}
        maxDate={moment().subtract(18, 'years').toDate()}
        placeholderText={placeholder}
        showYearDropdown={true}
        customInput={
          <PatternFormat
            mask="_"
            format="##-##-####"
            value={stateBirthday}
            className="form-control"
            placeholder={placeholder}
          />
        }
        onChange={(val) => {
          handleChange(val);
        }}
        onChangeRaw={(val) => {
          console.log('change');
          // @ts-ignore
          handleChange(val.target.value);
        }}
        onBlur={() => {
          if (!values[fieldKey]) {
            setFieldValue(fieldKey, '');
            setIsDefaultValue(false);
          }
        }}
      />
      {
        <>
          {isInvalidValue && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              Birthday field is invalid
            </div>
          )}
          <ErrorMessage
            name={fieldKey}
            render={(msg) => (
              <>
                {isDefaultValue && (
                  <div
                    className="invalid-feedback"
                    style={{ display: 'block' }}
                  >
                    {msg}
                  </div>
                )}
              </>
            )}
          />
        </>
      }
    </>
  );
};

export default MMDDatePickerFly;
