import { push } from 'redux-first-history';
import React from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { Creators as AuthActions } from '../../modules/auth.module';
import { MyState } from '../../store';
import { Route } from '../routes/routes';
import { MenuItem, Provider } from '../../typings';
import MMDMenuItem from './header/MMDMenuItem';
import { useNavigate } from 'react-router-dom';

const ROUTES_NO_MENU = [
  '/',
  '/signup',
  '/signup/signature-and-license',
  '/terms-and-conditions',
  '/student-attendance',
  '/patient-consent',
];
const ROUTE_PUBLIC_APPOINTMENT = '/public-appointment';
const ROUTE_PUBLIC_FEEDBACK = '/feedback-patient-public';
const ROUTE_VIDEO_PUBLIC = '/video-call-public';

type PropTypes = {
  currentLocation: string;
  doctorName: string;
  push: (path: string) => void;
  routes: Route[];
  isStudent: boolean;
  teacher?: any;
  provider: Provider;
} & typeof AuthActions;

const MMDHeader: React.FC<PropTypes> = ({
  currentLocation,
  push,
  routes,
  requestAuthOut,
  doctorName,
  isStudent,
  teacher,
  provider,
}) => {
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    const shouldShowMenu = currentLocation.includes(ROUTE_PUBLIC_APPOINTMENT)
      ? false
      : currentLocation.includes(ROUTE_PUBLIC_FEEDBACK)
        ? false
        : currentLocation.includes(ROUTE_VIDEO_PUBLIC)
          ? false
          : !ROUTES_NO_MENU.includes(currentLocation);
    if (shouldShowMenu) {
      const menuItems = routes
        .filter((r) => r.showMenuItem && (isStudent ? r.forStudent : true))
        .map((r) =>
          r.menuItem((path: string) => {
            push(path);
          }),
        );

      menuItems.push({
        onPress: () => {
          requestAuthOut();
        },
        text: 'Logout',
        path: '/',
      });

      setMenuItems(menuItems);
    } else {
      setMenuItems([]);
    }
  }, [push, routes, requestAuthOut, currentLocation, isStudent]);

  if (menuItems.length === 0) {
    return null;
  }

  const studentTitle = (text) => {
    if (text === 'My Appts' && isStudent) {
      return `Dr. ${teacher.firstName} ${teacher.lastName}'s Appts`;
    }
    return text;
  };

  return (
    <div className="header-bottom-border w-100">
      <Navbar
        className="mx-auto page-max-width"
        style={{ backgroundColor: '#FFFFFF' }}
        expand="lg"
      >
        <Navbar.Brand onClick={() => navigate('/dashboard')}>
          <img
            height="32"
            width="32"
            src="/assets/app-icon.png"
            alt="MomentMD"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="ml-auto">
            {menuItems.map((item) => (
              <MMDMenuItem
                key={item.text}
                menuItem={item}
                currentLocation={currentLocation}
                titleHelper={studentTitle}
              />
            ))}

            {!ROUTES_NO_MENU.includes(currentLocation) ? (
              <Nav.Link
                className="pl-4 pr-0"
                onClick={(e) => {
                  e.preventDefault();
                  if (!isStudent) {
                    push('/edit-profile');
                  } else {
                    push('/dashboard');
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#d8d8d8',
                    borderRadius: '50%',
                    borderWidth: '1px',
                    height: '30px',
                    width: '30px',
                    float: 'left',
                    marginRight: '5px',
                    marginTop: '-3px',
                  }}
                >
                  {provider.image ? (
                    <Image
                      src={provider.image}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      roundedCircle
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ color: '#FC346E' }}
                    />
                  )}
                </div>
                <span>
                  {!doctorName.includes('undefined') ? doctorName : ''}
                </span>
              </Nav.Link>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state: MyState) => ({
  currentLocation: state.router.location.pathname,
  doctorName: `${state.provider.firstName} ${state.provider.lastName}`,
  isStudent: !!(state.provider && state.provider.teacher),
  teacher: state.provider && state.provider.teacher,
  provider: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      ...AuthActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MMDHeader);
