import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Creators as TemplatesActions } from '../modules/template.module';
import { MyState } from '../store';
import {
  selectedChatroomSelectors,
  Creators as SelectedChatroomActions,
} from '../modules/chatroom/selected-chatroom.module';
import {
  Creators as BookingsActions,
  bookingsSelectors,
} from '../modules/bookings.module';
import { Message, Chatroom, Booking } from '../typings';
import MessageList from './chat/MessageList';
import MessageCompose from './chat/MessageCompose';
import EPrescribing from '../components/EPrescribing';
import TimeSlotCalendar from './myappts/TimeSlotCalendar';
import { useDimension } from '../hooks/useDimensions';
import NotesTextArea, {
  getNotesString,
  noteSections,
} from '../components/notes/notesTextArea';
import { useLocation } from 'react-router-dom';

type Props = {
  chatroom?: Chatroom;
  messages?: Message[];
  doctorId: string;
  booking: Booking;
  templates: any;
  requestTemplates: any;
} & typeof SelectedChatroomActions &
  typeof BookingsActions &
  typeof TemplatesActions;

const Chat: React.FC<Props> = ({
  chatroom,
  messages,
  sendMessage,
  requestLastBooking,
  doctorId,
  booking,
  updateBookingNote,
  requestOldMessagesForChatroom,
  templates,
  requestTemplates,
  requestChatroom,
}) => {
  const [step, setStep] = useState(1);
  const [prescribing, setPrescribing] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showTimeslot, setShowTimeslot] = useState(true);
  const [dynamicHeightMessageList, setDynamicHeightMessageList] = useState({});
  const [dynamicHeightSlotPanel, setDynamicHeightSlotPanel] = useState({});
  //Saved notes not implemented here
  const [notesObject, setNotesObject] = useState<noteSections[]>([
    { id: 0, name: 'Basic', value: '', noTitle: true },
  ]);
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(
    noteTemplates && noteTemplates[0],
  );

  const { height } = useDimension();
  const refMessageCompose = React.useRef<HTMLDivElement | null>(null);
  const refMessageList = React.useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  const handlerSelectTemplate = (value) => {
    if (value == 0) {
      setTemplateSelected('basic');
    } else {
      setTemplateSelected(templates.entities[value]);
    }
  };

  useLayoutEffect(() => {
    if (!refMessageList || !refMessageList.current) return;
    if (!refMessageCompose || !refMessageCompose.current) return;
    const rectMessageList = refMessageList.current.getBoundingClientRect();
    const rectMessageCompose =
      refMessageCompose.current.getBoundingClientRect();
    const heightSlotPanel = height - (rectMessageList as any).y;
    const heightMessageList =
      heightSlotPanel - (rectMessageCompose as any).height;
    setDynamicHeightSlotPanel({ height: heightSlotPanel - 5 });
    setDynamicHeightMessageList({ height: heightMessageList - 5 });
  }, [refMessageList.current, refMessageCompose.current, height]);

  useMemo(() => {
    if (templateSelected) {
      if (templateSelected != 'basic') {
        const object =
          templateSelected &&
          templateSelected.sections.map((sec) => ({
            id: sec.id,
            name: sec.name,
            value: '',
          }));
        setNotesObject(object);
      } else {
        setNotesObject([{ id: 0, name: 'Basic', value: '', noTitle: true }]);
      }
    }
  }, [templateSelected]);

  useMemo(() => {
    setNoteTemplates(
      templates && templates.ids.map((id) => templates.entities[id]),
    );
  }, [templates]);

  useEffect(() => {
    requestTemplates();
    if (chatroom) {
      requestLastBooking({ patientId: chatroom.otherParticipant.id, doctorId });
    }

    if (!chatroom) {
      requestChatroom({ location });
    }
  }, [chatroom, doctorId]);

  const prescriptionPreviousStep = useCallback(() => {
    if (step === 1) {
      setPrescribing(false);
    } else {
      setStep(step - 1);
    }
  }, [setPrescribing, step, setStep]);

  const prescriptionNextStep = useCallback(
    (step) => {
      if (!prescribing) {
        setPrescribing(true);
      } else {
        if (step === 0) {
          setPrescribing(false);
          setStep(1);
        } else {
          setStep(step);
        }
      }
    },
    [prescribing, setPrescribing, setStep],
  );

  if (!chatroom) {
    return null;
  }

  const name = chatroom.otherParticipant
    ? chatroom.otherParticipant.firstName
      ? `${chatroom.otherParticipant.firstName} ${chatroom.otherParticipant.lastName}`
      : `${chatroom.otherParticipant.name}`
    : '';

  const renderNotes = () => {
    setShowNotes(true);
    setPrescribing(false);
    setShowTimeslot(false);
  };

  const sendComment = () => {
    const noteString = getNotesString();
    updateBookingNote({
      id: booking.id,
      bookingNote: noteString,
    });
    setShowTimeslot(true);
    setShowNotes(false);
  };

  return chatroom && messages && chatroom.otherParticipant ? (
    <Container fluid>
      <Row>
        <Col xs={12} className="text-center align-middle mt-5 mb-3">
          <h1>Chat with {name}</h1>
        </Col>
      </Row>
      <Row className="align-middle">
        <Col className="chat ml-3 mr-3" xs={12} sm={12} md={12} lg={7}>
          <div
            ref={refMessageList}
            style={dynamicHeightMessageList}
            className="d-flex"
          >
            <MessageList
              getMoreMessages={() =>
                requestOldMessagesForChatroom({ chatroomId: chatroom.id })
              }
              chatroom={chatroom}
              messages={messages}
            />
          </div>
          <div ref={refMessageCompose}>
            <MessageCompose
              chatroomId={chatroom.id}
              sendMessage={sendMessage}
              patientId={chatroom.otherParticipant.id}
              doctorId={doctorId}
            />
          </div>
        </Col>

        <Col
          className="chat ml-lg-3"
          lg={4}
          style={{ ...dynamicHeightSlotPanel, overflow: 'scroll' }}
        >
          {prescribing && (
            <>
              <img
                className="mx-2 my-3"
                style={{ objectFit: 'contain', cursor: 'pointer' }}
                height="32"
                width="32"
                src="/assets/left-arrow.png"
                alt="Previous step"
                onClick={prescriptionPreviousStep}
              />
              <EPrescribing
                isNewPrescription={true}
                className="my-3 px-0"
                step={step}
                onChangeStep={prescriptionNextStep}
                patientId={chatroom.otherParticipant.id}
              />
            </>
          )}
          {showTimeslot && !prescribing && (
            <>
              <h2 className="mt-3 text-center">
                Recommend appointment with patient
              </h2>

              <Col className="mx-auto pl-0 pr-0">
                <TimeSlotCalendar />
              </Col>

              <h6 className="my-5 text-center text-muted">
                You can choose recommendations
              </h6>

              {booking && booking.id && (
                <Button
                  className="w-100 mb-2"
                  variant="primary"
                  onClick={renderNotes}
                >
                  Write down notes for patient
                </Button>
              )}

              <Button
                className="w-100 mb-2"
                variant="primary"
                onClick={prescriptionNextStep}
              >
                E-Prescribe or Refill Prescription
              </Button>
            </>
          )}
          {showNotes && (
            <>
              <img
                className="mx-2 my-3"
                style={{ objectFit: 'contain', cursor: 'pointer' }}
                height="32"
                width="32"
                src="/assets/left-arrow.png"
                alt="Previous step"
                onClick={() => {
                  setShowTimeslot(true);
                  setShowNotes(false);
                }}
              />
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Write notes for patient</Form.Label>
                <Col xs={12}>
                  <span className="ml-3 form-label">Select template note:</span>
                  <select
                    onChange={(e) => handlerSelectTemplate(e.target.value)}
                    className="w-100 form-control"
                    style={{ marginBottom: '5px' }}
                  >
                    <option key={0} value={0}>
                      Basic Template
                    </option>
                    {noteTemplates.map((template) => (
                      <option
                        key={template.id}
                        value={template.id}
                        selected={
                          templateSelected &&
                          templateSelected != 'basic' &&
                          templateSelected.id == template.id
                        }
                      >
                        {template.title}
                      </option>
                    ))}
                  </select>
                </Col>
                <NotesTextArea notes={notesObject} />
              </Form.Group>
              <Button
                className="w-100 mb-2"
                variant="primary"
                onClick={sendComment}
              >
                Send note
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Container>
  ) : null;
};

const mapStateToProps = (state: MyState) => ({
  chatroom:
    state.chatrooms.entities[state.chatroom.selectedChatroom.chatroomId],
  messages: selectedChatroomSelectors.selectAll(
    state.chatroom.selectedChatroom,
  ),
  doctorId: state.provider.id,
  booking: bookingsSelectors.selectAll(state.bookings)[0],
  templates: state.templates,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
  bindActionCreators(
    {
      ...SelectedChatroomActions,
      ...BookingsActions,
      ...TemplatesActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
