import { createSingleEventSaga, MyAction } from '@mrnkr/redux-saga-toolbox';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';

interface VisitHistoryPayload {
  visitId: string;
  patientId: string;
}

interface ActionTypes {
  REQUEST_VISIT_HISTORY: string;
  COMMIT_VISIT_HISTORY: string;
  CLEAR_VISIT_HISTORY: string;
}

interface ActionCreators {
  requestVisitHistory: (
    payload: VisitHistoryPayload,
  ) => MyAction<VisitHistoryPayload>;
  commitVisitHistory: (payload: any) => MyAction<any>;
  clearVisitHistory: () => MyAction<any>;
}

export type VisitHistoryState = object;

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestVisitHistory: ['payload'],
  commitVisitHistory: ['payload'],
  clearVisitHistory: [],
});

const initialState = {};

function commitVisitHistory(
  _: VisitHistoryState,
  action: MyAction<any>,
): VisitHistoryState {
  return action.payload;
}

function clearVisitHistory(
  _: VisitHistoryState,
  action: MyAction<any>,
): VisitHistoryState {
  return {};
}

export const visitHistoryReducer = createReducer(initialState, {
  [Types.COMMIT_VISIT_HISTORY]: commitVisitHistory,
  [Types.CLEAR_VISIT_HISTORY]: clearVisitHistory,
});

async function downloadVisitHistory({
  headers,
  ...payload
}: ArgsWithHeaders<VisitHistoryPayload>): Promise<any> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/specific-visit-history/${payload.patientId}/${payload.visitId}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const visitHistory = await result.json();
  return visitHistory.booking;
}

const requestVisitHistoryWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<any>
>({
  takeEvery: Types.REQUEST_VISIT_HISTORY,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitVisitHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadVisitHistory,
  beforeAction: putAuthInfoInArgs,
});

export const visitHistorySagas = [requestVisitHistoryWatcher];
