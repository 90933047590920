import {
  createSingleEventSaga,
  MyAction,
  EntityState,
  createEntityAdapter,
  composeSagas,
} from '@mrnkr/redux-saga-toolbox';
import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { SagaIterator } from 'redux-saga';

import { putAuthInfoInArgs } from './auth.module';
import { AUTH_API_URL } from '../config';
import { Booking, Patient, BookingNotes } from '../typings';
import { ArgsWithHeaders, LocationChangeActionPayload } from '../utils/typings';
import { onRoute, extractRouteParams } from '../utils/onRoute';
import { noOpAction } from '../utils/noOpAction';
import { Creators as LoadingActions } from './loading.module';
import { Creators as ErrorActions } from './errors.module';
import { getFirebaseImage } from '../utils/Firebase';
import parseQueryParams from '../utils/parseQueryParams';
import { MMDError } from '../utils/MMDError';
import {
  requestPatientFromFirestore,
  requestSectionFromFirestore,
} from '../utils/helper';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import { replace } from 'redux-first-history';
import { createSelector } from 'reselect';

interface BookingQuery {
  from: string;
  to: string;
}

interface BookingId {
  id: string;
}

interface Feedback {
  id: string;
  rating: number;
  comment: string;
}

interface ActionTypes {
  REQUEST_UPDATE_APPOINTMENT: string;
  REQUEST_APPOINTMENTS: string;
  REQUEST_APPOINTMENT: string;
  COMMIT_APPOINTMENTS: string;
  COMMIT_APPOINTMENT: string;
  REQUEST_LAST_BOOKING: string;
  UPDATE_BOOKING_NOTE: string;
  SEND_FEEDBACK: string;
  SEND_SUBSCRIBER_STUDENT: string;
  SEND_UNSUBSCRIBER_STUDENT: string;
}

interface ActionCreators {
  requestAppointments: (payload: BookingQuery) => MyAction<BookingQuery>;
  requestAppointment: (payload: BookingId) => MyAction<BookingId>;
  requestUpdateAppointment: (
    payload: Partial<Booking>,
  ) => MyAction<Partial<Booking>>;
  commitAppointments: (payload: Booking[]) => MyAction<Booking[]>;
  commitAppointment: (payload: Booking) => MyAction<Booking>;
  requestLastBooking: (payload: Partial<Booking>) => MyAction<Booking>;
  updateBookingNote: (
    payload: Partial<Booking & { redirectURL?: string }>,
  ) => MyAction<Booking>;
  successUpdate: () => MyAction<void>;
  sendFeedback: (payload: Feedback) => MyAction<void>;
  sendSubscriberStudent: (payload: BookingId) => MyAction<void>;
  sendUnsubscriberStudent: (payload: BookingId) => MyAction<void>;
}

export interface BookingsState extends EntityState<Booking> {}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAppointments: ['payload'],
  requestAppointment: ['payload'],
  commitAppointments: ['payload'],
  commitAppointment: ['payload'],
  requestUpdateAppointment: ['payload'],
  requestLastBooking: ['payload'],
  updateBookingNote: ['payload'],
  successUpdate: null,
  sendFeedback: ['payload'],
  sendSubscriberStudent: ['payload'],
  sendUnsubscriberStudent: ['payload'],
});

const entityAdapter = createEntityAdapter<Booking>();
const initialState = entityAdapter.getInitialState();
export const bookingsSelectors = entityAdapter.getSelectors();

function commitBookings(
  state: BookingsState,
  action: MyAction<Booking[]>,
): BookingsState {
  return entityAdapter.addAll(action.payload, state);
}

function commitBooking(
  state: BookingsState,
  action: MyAction<Booking>,
): BookingsState {
  return entityAdapter.addAll([action.payload], state);
}

export const bookingsReducer = createReducer<BookingsState, AnyAction>(
  initialState,
  {
    [Types.COMMIT_APPOINTMENTS]: commitBookings,
    [Types.COMMIT_APPOINTMENT]: commitBooking,
  },
);

async function downloadAppointments({
  headers,
  ...payload
}: ArgsWithHeaders<BookingQuery>): Promise<Booking[]> {
  const query = parseQueryParams(payload);
  const result = await fetch(
    `${AUTH_API_URL}/doctors/appointments/all?${query}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }

  const { appointments }: { appointments: Booking[] } = await result.json();

  for (const appointment of appointments) {
    const patientInfo = await requestPatientFromFirestore(
      (appointment as any).patient as Patient,
    );
    appointment.peerName = patientInfo.firstName
      ? `${patientInfo.firstName} ${patientInfo.lastName}`
      : patientInfo.name;

    const { values } = await requestSectionFromFirestore(
      (appointment as any).patient as Patient,
      'conditions',
    );
    appointment.conditions = values.join(', ');

    const pictureUri = await getFirebaseImage(
      `profile/${appointment.patientId}.jpeg`,
    );
    appointment.avatar = pictureUri;
    if (appointment.studentId) {
      const student = await requestPatientFromFirestore({
        id: (appointment as any).studentId,
      } as Patient);
      appointment.studentName = `${student.firstName} ${student.lastName}`;
    }
  }
  const appointmentsFilter = appointments.filter(
    ({ event, doctorRating, patientRating }) =>
      moment(event.end.dateTime).isAfter(moment.utc()) &&
      !doctorRating &&
      !patientRating,
  );
  return appointmentsFilter;
}

async function downloadAppointment({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: string }>): Promise<Booking> {
  const result = await fetch(`${AUTH_API_URL}/bookings/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
  const { booking }: { booking: Booking } = await result.json();
  return booking;
}

async function updateAppointment({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Booking>>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/appointments/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
}

async function downloadLastBooking({
  headers,
  ...payload
}: ArgsWithHeaders<Partial<Booking>>): Promise<Booking> {
  const result = await fetch(`${AUTH_API_URL}/bookings/get-last-booking`, {
    headers,
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
  const { booking }: { booking: Booking } = await result.json();
  return booking || ({ id: '' } as any);
}

async function updateBookingNote({
  headers,
  ...payload
}: ArgsWithHeaders<
  Partial<Booking & { redirectURL?: string }>
>): Promise<Booking> {
  const result = await fetch(`${AUTH_API_URL}/bookings/${payload.id}/notes`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ note: payload.bookingNote }),
  });

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
  const response = await result.json();

  response.data.redirectURL = payload.redirectURL;

  return response.data;
}

async function sendFeedback({
  headers,
  ...payload
}: ArgsWithHeaders<Feedback>): Promise<Booking> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/${payload.id}/booking-feedback`,
    {
      headers,
      method: 'POST',
      body: JSON.stringify({
        rating: payload.rating,
        comment: payload.comment,
        fromDoctor: true,
      }),
    },
  );

  if (!result.ok) {
    throw new MMDError('An error has occurred');
  }
  const { booking }: { booking: Booking } = await result.json();
  return booking;
}

async function sendSubscriberStudent({
  headers,
  ...payload
}: ArgsWithHeaders<BookingId>): Promise<Booking> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/${payload.id}/subscribe-student`,
    {
      headers,
      method: 'POST',
    },
  );

  if (!result.ok) {
    if (result.status === 409) {
      throw new MMDError(
        'Another student is already subscribed to this booking.',
      );
    }
    if (result.status === 418) {
      throw new MMDError(
        "You can't be subscribed to more than three future bookings.",
      );
    }
    throw new MMDError('An error has occurred');
  }
  const { booking }: { booking: Booking } = await result.json();
  return booking;
}

async function sendUnsubscriberStudent({
  headers,
  ...payload
}: ArgsWithHeaders<BookingId>): Promise<Booking> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/${payload.id}/unsubscribe-student`,
    {
      headers,
      method: 'DELETE',
    },
  );

  if (!result.ok) {
    if (result.status === 409) {
      throw new MMDError(
        'Cannot unsubscribe from calls starting in less than 24 hours.',
      );
    }
    throw new MMDError('An error has occurred');
  }
  const { booking }: { booking: Booking } = await result.json();
  return booking;
}

const requestUpdateAppointmentWatcher = createSingleEventSaga<
  Partial<Booking>,
  Partial<Booking>,
  MyAction<Partial<Booking>>
>({
  takeEvery: Types.REQUEST_UPDATE_APPOINTMENT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointment,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updateAppointment,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  // eslint-disable-next-line require-yield
  *afterAction(
    _,

    { headers, ...args }: ArgsWithHeaders<Partial<Booking>>,
  ): SagaIterator {
    return args;
  },
});

const requestAppointmentsWatcher = createSingleEventSaga<
  BookingQuery,
  Booking[],
  MyAction<BookingQuery>
>({
  takeEvery: Types.REQUEST_APPOINTMENTS,
  loadingAction: LoadingActions.setAppointmentsLoading,
  commitAction: Creators.commitAppointments,
  successAction: LoadingActions.setNotAppointmentsLoading,
  errorAction: ErrorActions.setError,
  action: downloadAppointments,
  beforeAction: putAuthInfoInArgs,
});

const requestAppointmentWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_APPOINTMENT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointment,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: downloadAppointment,
  beforeAction: putAuthInfoInArgs,
});

const requestAppointmentForVideoCallWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/video-call/:id'),
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointment,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadAppointment,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: string },
    ArgsWithHeaders<{ id: string }>
    // @ts-ignore
  >(extractRouteParams('/video-call/:id'), putAuthInfoInArgs),
});

const requestLastAppointmentWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.REQUEST_LAST_BOOKING,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointment,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadLastBooking,
  beforeAction: putAuthInfoInArgs,
});

const requestUpdateNoteWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.UPDATE_BOOKING_NOTE,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: updateBookingNote,
  beforeAction: putAuthInfoInArgs,
  // @ts-ignore
  *afterAction(
    bookingNote: BookingNotes & { redirectURL?: string },
  ): SagaIterator {
    if (bookingNote.redirectURL) {
      yield put(replace(bookingNote.redirectURL));
    }
  },
});

const sendFeedbackWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.SEND_FEEDBACK,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: LoadingActions.setNotLoading,
  errorAction: ErrorActions.setError,
  action: sendFeedback,
  beforeAction: putAuthInfoInArgs,
});

const sendStudentSubscriber = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.SEND_SUBSCRIBER_STUDENT,
  loadingAction: LoadingActions.setAppointmentsLoading,
  commitAction: Creators.commitAppointment,
  successAction: LoadingActions.setNotAppointmentsLoading,
  errorAction: ErrorActions.setError,
  action: sendSubscriberStudent,
  beforeAction: putAuthInfoInArgs,
});

const sendStudentUnsubscriber = createSingleEventSaga<
  LocationChangeActionPayload,
  Booking,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: Types.SEND_UNSUBSCRIBER_STUDENT,
  loadingAction: LoadingActions.setAppointmentsLoading,
  commitAction: Creators.commitAppointment,
  successAction: LoadingActions.setNotAppointmentsLoading,
  errorAction: ErrorActions.setError,
  action: sendUnsubscriberStudent,
  beforeAction: putAuthInfoInArgs,
});

export const bookingsSagas = [
  sendStudentUnsubscriber,
  sendStudentSubscriber,
  requestAppointmentsWatcher,
  requestUpdateAppointmentWatcher,
  requestAppointmentWatcher,
  requestAppointmentForVideoCallWatcher,
  requestLastAppointmentWatcher,
  requestUpdateNoteWatcher,
  sendFeedbackWatcher,
];

const selectBookingsState = (state) => state.bookings;

export const selectBookingById = createSelector(
  [selectBookingsState, (_, id) => id],
  (state, id) => state.entities[id],
);
