import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { SagaIterator } from 'redux-saga';
import { push } from 'redux-first-history';
import momentTimezone from 'moment-timezone';
import { doc, getDoc } from 'firebase/firestore';
import { createActions, createReducer } from 'reduxsauce';
import { select, put, call, take, retry, race } from 'redux-saga/effects';
import {
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  MyAction,
  Dictionary,
  createSingleEventSaga,
  SagaIterator as SagaIteratorToolbox,
} from '@mrnkr/redux-saga-toolbox';

import { MyState } from '../store';
import { Provider } from '../typings';
import { AUTH_API_URL } from '../config';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { ArgsWithHeaders } from '../utils/typings';
import { auth, firestore } from '../utils/Firebase';
import { Creators as LoadingActions } from './loading.module';
import { PUBLIC_USER_CUSTOM_TOKEN } from '../utils/constants';
import { Creators as ErrorActions, Types as ErrorTypes } from './errors.module';
import {
  Types as ProviderTypes,
  Creators as ProviderActions,
} from '../modules/provider.module';

interface VerifyResult {
  response: boolean;
  msg: string;
}

interface AuthPayload {
  email: string;
  password: string;
}

export interface AuthResult {
  firebase_token: string;
  custom_token: string;
  expiration: string;
  lastEmailLogged: string;
}

export interface LastEmailLoggedPayload {
  lastEmailLogged: string;
}

interface ActionTypes {
  REQUEST_AUTH: string;
  REQUEST_AUTH_PUBLIC: string;
  REQUEST_AUTH_OUT: string;
  LOADING_AUTH: string;
  LOADING_AUTH_OUT: string;
  COMMIT_AUTH: string;
  COMMIT_AUTH_OUT: string;
  SUCCESS_AUTH: string;
  VERIFY_ACCOUNT: string;
  RESEND_CODE: string;
  REQUEST_LAST_EMAIL_LOGGED: string;
  COMMIT_LAST_EMAIL_LOGGED: string;
}

interface VerifyPayload {
  verificationCode: string;
}

interface ActionCreators {
  verifyAccount: (payload: VerifyPayload) => MyAction<VerifyPayload>;
  requestAuth: (payload: AuthPayload) => MyAction<AuthPayload>;
  requestAuthPublic: (payload: AuthPayload) => MyAction<AuthPayload>;
  requestAuthOut: () => MyAction<void>;
  commitAuth: (payload: AuthResult | AuthPayload) => MyAction<AuthResult>;
  commitAuthOut: () => MyAction<void>;
  successAuth: (payload?: AuthResult) => MyAction<AuthResult>;
  successVerify: (payload?: VerifyResult) => MyAction<VerifyResult>;
  resendCode: () => MyAction<void>;
  successResend: () => MyAction<void>;
  requestLastEmailLogged: () => MyAction<void>;
  commitLastEmailLogged: (
    payload?: LastEmailLoggedPayload,
  ) => MyAction<LastEmailLoggedPayload>;
}

export interface AuthState extends Partial<AuthResult> {
  authenticated: boolean;
  lastEmailLogged: string;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestAuth: ['payload'],
  requestAuthPublic: ['payload'],
  requestAuthOut: [],
  commitAuth: ['payload'],
  commitAuthOut: [],
  successAuth: ['payload'],
  verifyAccount: ['payload'],
  resendCode: [],
  successVerify: ['payload'],
  successResend: [],
  requestLastEmailLogged: [],
  commitLastEmailLogged: ['payload'],
});

function commitAuthResult(
  state: AuthState,
  action: MyAction<AuthResult>,
): AuthState {
  return {
    ...state,
    authenticated: true,
    ...action.payload,
  };
}

function verifyAccountAction(
  state: AuthState,
  action: MyAction<AuthResult>,
): AuthState {
  return {
    ...state,
    authenticated: true,
    ...action.payload,
  };
}

function commitAuthResultOut(state: AuthState): AuthState {
  return {
    ...state,
    authenticated: false,
  };
}

function commitLastEmailLogged(
  state: AuthState,
  action: MyAction<LastEmailLoggedPayload>,
): AuthState {
  return {
    ...state,
    lastEmailLogged: action.payload!.lastEmailLogged,
  };
}

const signInWithEmailPasswordRequest = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const requestUserFromFirestore = async (uid) => {
  const docRef = await getDoc(doc(firestore, 'users', uid));
  return docRef.data();
};

const initialState: AuthState = {
  authenticated: false,
  lastEmailLogged: '',
};

export const authReducer = createReducer(initialState, {
  [Types.COMMIT_AUTH]: commitAuthResult,
  [Types.COMMIT_AUTH_OUT]: commitAuthResultOut,
  [Types.VERIFY_ACCOUNT]: verifyAccountAction,
  [Types.COMMIT_LAST_EMAIL_LOGGED]: commitLastEmailLogged,
});

function* authenticatePublic(args: AuthPayload): SagaIterator {
  const user_id = uuidv4();
  const headers = new Headers();
  yield call(createSession, headers, args.email, user_id);
  return {
    custom_token: PUBLIC_USER_CUSTOM_TOKEN,
    firebase_token: 'older_token',
    expiration: moment().format(),
  };
}

function* authenticate(args: AuthPayload): SagaIterator {
  const headers = new Headers();
  const userData = yield call(
    signInWithEmailPasswordRequest,
    args.email,
    args.password,
  );

  if (auth && auth.currentUser) {
    const token = yield call([auth.currentUser, 'getIdToken'], true);
    headers.append('Authorization', token);
    const firestoreUser = yield call(
      requestUserFromFirestore,
      userData.user.uid,
    );
    const postgresPromise = yield call(
      fetch,
      `${AUTH_API_URL}/doctors/own-details`,
      {
        headers,
        method: 'GET',
      },
    );

    if (!postgresPromise.ok) {
      const authInfo: AuthResult = JSON.parse(
        localStorage.getItem('moment.session') || '',
      );

      if (authInfo) {
        yield call([localStorage, 'removeItem'], 'moment.session');
        throw Error();
      }

      throw new MMDError('An error has occurred');
    }

    const postgresUser = yield call([postgresPromise, 'json']);

    if (
      !postgresUser.doctor.adminVerified &&
      !['approved', 'pending'].includes(postgresUser.doctor.status)
    ) {
      throw new MMDError(`This Account is ${postgresUser.doctor.status}`);
    }
    if (postgresUser && postgresUser.doctor && postgresUser.doctor.teacherId) {
      const teachareFirebaseData = yield call(
        requestUserFromFirestore,
        postgresUser.doctor.teacherId,
      );
      postgresUser.doctor.subType = 'Student';
      postgresUser.doctor.teacher = teachareFirebaseData;
    }
    yield put(
      ProviderActions.commitProvider({
        ...firestoreUser,
        ...postgresUser.doctor,
      }),
    );

    yield call(createSession, headers, args.email, userData.user.uid);

    return {
      custom_token: postgresUser.customToken,
      firebase_token: token,
      expiration: moment().format(),
    };
  }
}

function customUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function setCookie(name, value, days = null) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}
function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

async function createSession(headers, userEmail, id) {
  headers.append('Content-Type', 'application/json');

  if (!getCookie('FCMToken')) {
    setCookie('FCMToken', `isWeb-!!-${customUUID()}`);
  }

  const FCMToken = getCookie('FCMToken');
  const sessionObj = {
    email: userEmail,
    id,
    location: 'lat: N/A long: N/A',
    additionalDeviceInfo: JSON.stringify({ userAgent: navigator.userAgent }),
    signin: new Date().toISOString(),
    FCMToken,
  };

  await fetch(`${AUTH_API_URL}/sessions`, {
    headers,
    method: 'POST',
    body: JSON.stringify(sessionObj),
  });

  await fetch(`${AUTH_API_URL}/users/timeZone`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({
      userId: id,
      type: 'Doctor',
      timeZone: momentTimezone.tz.guess(),
    }),
  });
}

async function updateSession(userEmail) {
  const authInfo: AuthResult = JSON.parse(
    localStorage.getItem('moment.session') || '',
  );

  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);

  const result = await fetch(`${AUTH_API_URL}/sessions/${userEmail}`, {
    headers,
    method: 'GET',
  });

  const resObj = await result.json();
  const lastSession = resObj && resObj.data[0];

  headers.append('Content-Type', 'application/json');

  await fetch(`${AUTH_API_URL}/sessions`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ ...lastSession, signout: new Date().toISOString() }),
  });
}

async function verifyAccount({
  headers,
  ...payload
}: ArgsWithHeaders<object>): Promise<VerifyPayload> {
  const result = await fetch(`${AUTH_API_URL}/verify`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ ...payload }),
  });

  if (!result.ok) {
    const error = await result.json();
    throw new MMDError(error.msg);
  }

  return result.json();
}

async function lastEmailLogged(): Promise<LastEmailLoggedPayload> {
  const lastEmailLogged = (await localStorage.getItem('lastEmailLogged')) || '';
  return { lastEmailLogged };
}

async function resendCode({ headers }: ArgsWithHeaders<object>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/resend-code`, {
    headers,
    method: 'POST',
  });

  if (!result.ok) {
    const error = await result.json();
    throw new MMDError(error.msg);
  }
}

const authenticateWatcher = createSingleEventSaga<
  AuthPayload,
  AuthResult,
  MyAction<AuthPayload>
>({
  takeEvery: Types.REQUEST_AUTH,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAuth,
  successAction: Creators.successAuth,
  errorAction: ErrorActions.setError,
  action: authenticate,
  *afterAction(res: AuthResult): SagaIteratorToolbox<any> {
    yield retry(3, 1000, persistLocalStorage, res);
  },
});

const authenticatePublicWatcher = createSingleEventSaga<
  AuthPayload,
  AuthResult,
  MyAction<AuthPayload>
>({
  takeEvery: Types.REQUEST_AUTH_PUBLIC,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAuth,
  successAction: Creators.successAuth,
  errorAction: ErrorActions.setError,
  action: authenticatePublic,
  *afterAction(res: AuthResult): SagaIteratorToolbox<any> {
    yield retry(3, 1000, persistPublicLocalStorage, res);
  },
});

const authenticateOutWatcher = createSingleEventSaga<any, any, MyAction<any>>({
  takeEvery: Types.REQUEST_AUTH_OUT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAuthOut,
  successAction: Creators.commitAuthOut,
  errorAction: ErrorActions.setError,
  action: signOut,
  *afterAction(): SagaIteratorToolbox<any> {
    yield put(push('/'));
  },
});

const verifyAccountWatcher = createSingleEventSaga<
  any,
  VerifyResult,
  MyAction<VerifyResult>
>({
  takeEvery: Types.VERIFY_ACCOUNT,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.successVerify,
  errorAction: ErrorActions.setError,
  action: verifyAccount,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,

    { headers, ...args }: ArgsWithHeaders<VerifyResult>,
  ): SagaIteratorToolbox<any> {
    yield put(push('/dashboard'));
    return args;
  },
});

const lastEmailLoggedWatcher = createSingleEventSaga<
  any,
  any,
  MyAction<LastEmailLoggedPayload>
>({
  takeEvery: Types.REQUEST_LAST_EMAIL_LOGGED,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitLastEmailLogged,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: lastEmailLogged,
});

const resendCodeWatcher = createSingleEventSaga<void, void, MyAction<void>>({
  takeEvery: Types.RESEND_CODE,
  loadingAction: LoadingActions.setLoading,
  commitAction: noOpAction,
  successAction: Creators.successResend,
  errorAction: ErrorActions.setError,
  action: resendCode,
  beforeAction: putAuthInfoInArgs,
});

async function signOut() {
  if (auth && auth.currentUser) {
    localStorage.setItem('lastEmailLogged', auth.currentUser.email!);
    await updateSession(auth.currentUser.email);
  }

  localStorage.removeItem('moment.session');
  await auth.signOut();
}

export function* putAuthInfoInArgs(args?: any): SagaIteratorToolbox<any> {
  const authInfo: AuthResult = JSON.parse(
    localStorage.getItem('moment.session') || '',
  );
  try {
    if (
      auth.currentUser &&
      authInfo &&
      authInfo.firebase_token &&
      authInfo.expiration &&
      moment().diff(moment(authInfo.expiration)) > 10000
    ) {
      const newToken = yield call([auth.currentUser, 'getIdToken'], true);
      authInfo.firebase_token = newToken as unknown as string;
      authInfo.expiration = moment().format();
      yield call(
        [localStorage, 'setItem'],
        'moment.session',
        JSON.stringify(authInfo),
      );
    }
  } catch (e) {
    console.log('error reset token', e);
  }
  const headers = new Headers();
  headers.append('Authorization', authInfo.firebase_token);
  headers.append('Content-Type', 'application/json');
  return { ...args, headers };
}

function isPublicAppointmentPath(pathname: string) {
  return pathname.includes('public-appointment');
}

function* checkPathnameForRedirect() {
  const {
    location: { pathname },
  } = yield select((state: MyState) => state.router);

  const noAuthPaths = [
    '/',
    '/signup',
    '/signup/signature-and-license',
    '/student-attendance',
    '/terms-and-conditions',
    '/patient-consent',
  ];

  if (!noAuthPaths.includes(pathname)) {
    if (!isPublicAppointmentPath(pathname)) {
      yield put(push('/'));
    }
  }
}

// eslint-disable-next-line require-yield
export function* putAuthPublicInfoInArgs(args = {}): SagaIteratorToolbox<any> {
  // yield call([localStorage, 'setItem'], 'moment.session', JSON.stringify(args));
  //yield call([auth, "signInWithCustomToken"], args.custom_token)
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  return { ...args, headers };
}

function* restoreSession() {
  const authInfo: AuthResult | null = JSON.parse(
    localStorage.getItem('moment.session'),
  );

  if (!authInfo) {
    yield call(checkPathnameForRedirect);
    return;
  }

  const isValidToken = yield call(isCustomTokenValid, authInfo.custom_token);

  if (!isValidToken) {
    yield call(signOut);
    yield put(push('/'));
    return;
  }

  yield call(redirect, authInfo);
}

function* redirect(authInfo: AuthResult): SagaIterator {
  if (authInfo.custom_token !== PUBLIC_USER_CUSTOM_TOKEN) {
    yield put(Creators.commitAuth(authInfo));
    const {
      location: { pathname },
    } = yield select((state: MyState) => state.router);

    yield put(ProviderActions.requestProvider());

    const { committed, error } = yield race({
      committed: take(ProviderTypes.COMMIT_PROVIDER),
      error: take(ErrorTypes.SET_ERROR),
    });
    if (error) {
      return;
    }
    const { payload: doctor }: MyAction<Provider> = committed;

    if (!doctor) {
      return;
    }

    if (doctor.twoStepVerification && !doctor.verified) {
      yield put(push('/verify'));
    } else if (pathname === '/') {
      yield put(push('/dashboard'));
    }
  }
}

async function isCustomTokenValid(token: string): Promise<boolean> {
  try {
    await signInWithCustomToken(auth, token);
    return true;
  } catch (err) {
    return false;
  }
}

function* navigateAfterAuth() {
  while (yield take(Types.SUCCESS_AUTH)) {
    const authInfo: AuthResult = JSON.parse(
      localStorage.getItem('moment.session') || '',
    );
    yield call(redirect, authInfo);
  }
}

function* persistLocalStorage(args: AuthResult): SagaIterator {
  yield call([localStorage, 'setItem'], 'moment.session', JSON.stringify(args));
  yield call(signInWithCustomToken, auth, args.custom_token);
  return args;
}
function* persistPublicLocalStorage(args: AuthResult): SagaIterator {
  yield call([localStorage, 'setItem'], 'moment.session', JSON.stringify(args));

  return args;
}

export const authSagas = [
  authenticateWatcher,
  restoreSession,
  navigateAfterAuth,
  authenticateOutWatcher,
  verifyAccountWatcher,
  resendCodeWatcher,
  lastEmailLoggedWatcher,
  authenticatePublicWatcher,
];

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function authFormValidator(values: any): Dictionary<string> {
  const errors: Dictionary<string> = {};
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (values.password && values.password.length < 3) {
    errors.password = `Password doesn't have the expected format`;
  }

  return errors;
}

export function verifyValidator(values: any): Dictionary<string> {
  const errors: Dictionary<string> = {};
  if (values['verificationCode'].length < 4) {
    errors.verificationCode = `Verification code must have 4 digits`;
  }
  return errors;
}
