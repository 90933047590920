import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';

import { getPdfUrl } from './preview-pdf/getPdfUrl';
import { Prescription } from '../../typings';

type Props = {
  patientId: string;
  prescription: Prescription;
  goToVerification: () => void;
};

const PreviewPdfStep: React.FC<Props> = ({
  patientId,
  prescription,
  goToVerification,
}) => {
  const [pdf, setPdf] = React.useState<ArrayBuffer | undefined>();
  const [pageNumber, setPageNumber] = React.useState(1);

  React.useEffect(() => {
    const fn = async () => {
      const arrBuf = await getPdfUrl(prescription, patientId);
      setPdf(arrBuf);
    };

    fn();
  }, [patientId, prescription]);

  const onDocumentLoadSuccess = React.useCallback(
    ({ numPages }) => {
      setPageNumber(numPages);
    },
    [setPageNumber],
  );

  return (
    <Container className="pt-2 pb-2" fluid>
      <Row>
        <Col xs={12} className="mb-4">
          {' '}
          Preview Prescription
        </Col>
        <Col xs={12} className="w-100">
          <Document file={{ data: pdf }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={350} />
          </Document>
        </Col>
      </Row>
      <Button
        className="w-100 mt-2 mb-2"
        variant="primary"
        onClick={goToVerification}
      >
        Send E-Prescription
      </Button>
    </Container>
  );
};

export default PreviewPdfStep;
