import { FormLabel } from 'react-bootstrap';
import { ErrorMessage, useField } from 'formik';
import Select, { GroupBase, Props } from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#f0f0f0' : 'white',
    borderColor: state.isFocused ? '#f81942' : '#dee2e6',
    '&:hover': {
      borderColor: '#f81942',
    },
    borderRadius: '22px',
    boxShadow: 'none',
  }),
  valueContainer: (styles) => ({ ...styles, padding: '6px 12px', margin: 0 }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected
      ? '#f81942'
      : state.isFocused
        ? '#f0f0f0'
        : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? 'none' : '#f0f0f0',
    },
    ':active': {
      backgroundColor: 'none',
    },
  }),
  input: (styles) => ({ ...styles, margin: 0, padding: 0 }),
  placeholder: (styles) => ({ ...styles, margin: 0 }),
};

const MMDSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  name,
  label,
  options,
  required,
  reduxForm,
  reduxValue,
  setFieldValue,
  ...props
}: Props<Option, IsMulti, Group> & {
  name: string;
  label?: string;
  required?: boolean;
  reduxForm?: boolean;
  reduxValue?: string;
  setFieldValue?: (fieldName: string, fieldValue: string) => void;
}) => {
  const [field, meta, helpers] = useField(name);

  const onChange = reduxForm
    ? ({ value }) => setFieldValue(name, value)
    : ({ value }) => helpers.setValue(value);

  const selectValue = options.find(
    // @ts-ignore
    ({ value }) => value === (reduxForm ? reduxValue : field.value),
  );

  return (
    <div>
      {label ? (
        <FormLabel className="ml-3 form-label" htmlFor={name}>
          {label}

          {required && <span className="text-danger ml-1">*</span>}
        </FormLabel>
      ) : (
        <div className="spacer-input-base" />
      )}

      <Select
        name={name}
        options={options}
        // @ts-ignore
        onChange={onChange}
        value={selectValue}
        styles={customStyles}
        isSearchable={false}
        {...props}
      />

      {meta.error && meta.touched && !reduxForm && (
        <span className="invalid-feedback d-block">{meta.error}</span>
      )}

      {reduxForm && (
        <ErrorMessage
          name="state"
          render={(msg) => (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {msg}
            </div>
          )}
        />
      )}
    </div>
  );
};

export default MMDSelect;
