import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  ErrorAction,
  MyAction,
  SagaIterator as SagaIteratorToolbox,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import { AUTH_API_URL } from '../config';
import {
  Booking,
  PaginationPayload,
  Patient,
  VisitHistoryBookingPublicInList,
} from '../typings';
import { getFirebaseImage } from '../utils/Firebase';
import { MMDError } from '../utils/MMDError';
import { noOpAction } from '../utils/noOpAction';
import { onRoute } from '../utils/onRoute';
import { ArgsWithHeaders, Paginated } from '../utils/typings';
import { putAuthInfoInArgs } from './auth.module';
import { Creators as ErrorActions } from './errors.module';
import { Creators as LoadingActions } from './loading.module';
import {
  requestPatientFromFirestore,
  requestSectionFromFirestore,
} from '../utils/helper';

import { select } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { MyState } from '../store';
import { appendPaginationParams } from '../utils/appendPaginationParams';

interface PatientId {
  id: string;
}

type PublicVisitHistoryPayload = PatientId & PaginationPayload;

interface AppointmentId {
  id: string;
}

interface PatientPayload {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  birthday: string;
  city: string;
  state: string;
  phone: string;
  gender: string;
  avatarApproved: boolean;
  parentId?: string;
  relativeType?: string;
}

interface ActionTypes {
  REQUEST_UPDATE_PATIENT: string;
  REQUEST_PATIENT: string;
  REQUEST_PATIENTS: string;
  LOADING_PATIENTS: string;
  COMMIT_PATIENTS: string;
  COMMIT_PATIENT: string;
  SUCCESS_PATIENTS: string;
  ERROR_PATIENTS: string;

  LOADING_PATIENT_REPORT: string;
  REQUEST_PATIENT_REPORT: string;
  COMMIT_PATIENT_REPORT: string;

  LOADING_PATIENT_FILES: string;
  REQUEST_PATIENT_FILES: string;
  COMMIT_PATIENT_FILES: string;

  REQUEST_PATIENT_VISIT_HISTORY: string;
  COMMIT_PATIENT_VISIT_HISTORY: string;

  REQUEST_PATIENT_PUBLIC_VISIT_HISTORY: string;
  COMMIT_PATIENT_PUBLIC_VISIT_HISTORY: string;

  REQUEST_APPOINTMENT_FAMILY_HISTORY: string;
  COMMIT_APPOINTMENT_FAMILY_HISTORY: string;

  REQUEST_APPOINTMENT_MEDICAL_HISTORY: string;
  COMMIT_APPOINTMENT_MEDICAL_HISTORY: string;

  REQUEST_APPOINTMENT_LIFESTYLE: string;
  COMMIT_APPOINTMENT_LIFESTYLE: string;
}

interface ActionCreators {
  requestPatient: (payload: PatientId) => MyAction<Patient>;
  requestPatients: () => MyAction<void>;
  requestPatientReport: (payload: PatientId) => MyAction<void>;
  requestPatientFiles: (payload: PatientId) => MyAction<void>;
  requestUpdatePatient: (payload: PatientPayload) => MyAction<PatientPayload>;
  loadingPatients: () => Action;
  loadingPatientReport: () => Action;
  loadingPatientFiles: () => Action;
  commitPatients: (payload: Patient[]) => MyAction<Patient[]>;
  commitPatient: (payload: Patient) => MyAction<Patient>;
  commitPatientReport: (payload: string) => MyAction<string>;
  commitPatientFiles: (payload: string | File[]) => MyAction<File[]>;
  successPatients: (payload: Patient[]) => MyAction<Patient[]>;
  errorPatients: (payload) => object;

  requestPatientVisitHistory: (payload: AppointmentId) => MyAction<void>;
  commitPatientVisitHistory: (
    payload: string | Booking[],
  ) => MyAction<Booking[]>;

  requestPatientPublicVisitHistory: (
    payload: PublicVisitHistoryPayload,
  ) => MyAction<PublicVisitHistoryPayload>;
  commitPatientPublicVisitHistory: (
    payload: Paginated<VisitHistoryBookingPublicInList>,
  ) => MyAction<Paginated<VisitHistoryBookingPublicInList>>;

  requestAppointmentFamilyHistory: (payload: PatientId) => MyAction<void>;
  commitAppointmentFamilyHistory: (payload: string) => MyAction<any>;

  requestAppointmentMedicalHistory: (payload: PatientId) => MyAction<void>;
  commitAppointmentMedicalHistory: (payload: string) => MyAction<any>;

  requestAppointmentLifestyle: (payload: PatientId) => MyAction<void>;
  commitAppointmentLifestyle: (payload: string) => MyAction<any>;
}

export interface PatientsState extends EntityState<Patient> {
  count: number;
  report?: string;
  loadingReport: boolean;
  files: File[];
  loadingFiles: boolean;
  versionReport: number;
  visitHistory: {
    data: Booking[];
    count: number;
    isLoading: boolean;
  };
  publicVisitHistory: {
    data: VisitHistoryBookingPublicInList[];
    count: number;
    isLoading: boolean;
  };
  familyHistory: any;
  medicalHistory: any;
  lifestyles: any[];
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestPatients: [],
  requestPatient: ['payload'],
  requestPatientReport: ['payload'],
  requestPatientFiles: ['payload'],
  loadingPatients: [],
  loadingPatientReport: [],
  loadingPatientFiles: [],
  commitPatients: ['payload'],
  commitPatient: ['payload'],
  commitPatientReport: ['payload'],
  commitPatientFiles: ['payload'],
  successPatients: ['payload'],
  requestUpdatePatient: ['payload'],
  errorPatients: ['error'],

  requestPatientVisitHistory: ['payload'],
  commitPatientVisitHistory: ['payload'],

  requestPatientPublicVisitHistory: ['payload'],
  commitPatientPublicVisitHistory: ['payload'],

  requestAppointmentFamilyHistory: ['payload'],
  commitAppointmentFamilyHistory: ['payload'],

  requestAppointmentMedicalHistory: ['payload'],
  commitAppointmentMedicalHistory: ['payload'],

  requestAppointmentLifestyle: ['payload'],
  commitAppointmentLifestyle: ['payload'],
});

const entityAdapter = createEntityAdapter<Patient>();
const initialState = entityAdapter.getInitialState({
  count: 0,
  loadingReport: false,
  loadingFiles: false,
  files: [],
  versionReport: 0,
  visitHistory: {
    data: [],
    count: 0,
    isLoading: false,
  },
  publicVisitHistory: {
    data: [],
    count: 0,
    isLoading: false,
  },
  lifestyles: [],
});
export const patientsSelectors = entityAdapter.getSelectors();

export const selectPatientsState = (state: MyState) => state.patients;

export const selectPatientById = createSelector(
  [selectPatientsState, (_, id) => id],
  (state, id) => state.entities[id],
);
export const patientSelector = (state, patientId): Patient => {
  if (!state.patients.entities) return null;
  return state.patients.entities[patientId];
};

function setLoadingReport(state: PatientsState): PatientsState {
  return {
    ...state,
    loadingReport: true,
  };
}

function setLoadingFiles(state: PatientsState): PatientsState {
  return {
    ...state,
    loadingFiles: true,
  };
}

function commitPatients(
  state: PatientsState,
  action: MyAction<Patient[]>,
): PatientsState {
  return {
    ...entityAdapter.addAll(action.payload || [], state),
  };
}

function commitPatientVisitHistory(
  state: PatientsState,
  action: MyAction<Paginated<Booking>>,
): PatientsState {
  return {
    ...state,
    visitHistory: {
      ...action.payload,
      isLoading: false,
    },
  };
}

function commitAppointmentFamilyHistory(
  state: PatientsState,
  action: MyAction<any[]>,
): PatientsState {
  return {
    ...state,
    familyHistory: action.payload,
  };
}

function commitAppointmentMedicalHistory(
  state: PatientsState,
  action: MyAction<any>,
): PatientsState {
  return {
    ...state,
    medicalHistory: action.payload,
  };
}

function commitAppointmentlifestyle(
  state: PatientsState,
  action: MyAction<any[]>,
): PatientsState {
  return {
    ...state,
    lifestyles: action.payload || [],
  };
}

function commitPatient(
  state: PatientsState,
  action: MyAction<Patient>,
): PatientsState {
  return {
    ...entityAdapter.upsertOne(action.payload!, state),
  };
}

function commitPatientReport(
  state: PatientsState,
  action: MyAction<string>,
): PatientsState {
  return {
    ...state,
    report: action.payload,
    loadingReport: false,
    versionReport: state.versionReport + 1,
  };
}

function commitPatientFiles(
  state: PatientsState,
  action: MyAction<File[]>,
): PatientsState {
  return {
    ...state,
    files: action.payload || [],
    loadingFiles: false,
  };
}

function setError<TError extends Error = Error>(
  state: PatientsState,

  { error }: ErrorAction<TError>,
): PatientsState {
  return {
    ...state,
  };
}

function requestPatientPublicVisitHistory(state: PatientsState): PatientsState {
  return {
    ...state,
    publicVisitHistory: {
      ...state.publicVisitHistory,
      isLoading: true,
    },
  };
}

function commitPatientPublicVisitHistory(
  state: PatientsState,
  action: MyAction<Paginated<VisitHistoryBookingPublicInList>>,
): PatientsState {
  return {
    ...state,
    publicVisitHistory: {
      data: action.payload.data,
      count: action.payload.count,
      isLoading: false,
    },
  };
}

export const patientsReducer = createReducer(initialState, {
  [Types.LOADING_PATIENT_REPORT]: setLoadingReport,
  [Types.LOADING_PATIENT_FILES]: setLoadingFiles,
  [Types.COMMIT_PATIENTS]: commitPatients,
  [Types.COMMIT_PATIENT_REPORT]: commitPatientReport,
  [Types.COMMIT_PATIENT_FILES]: commitPatientFiles,
  [Types.ERROR_PATIENTS]: setError,
  [Types.COMMIT_PATIENT]: commitPatient,
  [Types.COMMIT_PATIENT_VISIT_HISTORY]: commitPatientVisitHistory,
  [Types.COMMIT_APPOINTMENT_FAMILY_HISTORY]: commitAppointmentFamilyHistory,
  [Types.COMMIT_APPOINTMENT_MEDICAL_HISTORY]: commitAppointmentMedicalHistory,
  [Types.COMMIT_APPOINTMENT_LIFESTYLE]: commitAppointmentlifestyle,
  [Types.REQUEST_PATIENT_PUBLIC_VISIT_HISTORY]:
    requestPatientPublicVisitHistory,
  [Types.COMMIT_PATIENT_PUBLIC_VISIT_HISTORY]: commitPatientPublicVisitHistory,
});

async function downloadPatients({
  headers,
}: ArgsWithHeaders<Patient[]>): Promise<Patient[]> {
  const result = await fetch(`${AUTH_API_URL}/doctors/patients/all`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }

  const { patients } = await result.json();
  for (const patient of patients) {
    const patientInfo = await requestPatientFromFirestore(patient);
    Object.keys(patientInfo).forEach((key) => {
      patient[key] = patientInfo[key];
    });
  }

  for (const patient of patients) {
    const pictureUri = await getFirebaseImage(`profile/${patient.id}.jpeg`);
    patient.avatar = pictureUri;
    patient.birthday = patient.birthday.replace(/-/g, '/');
  }

  return [...patients];
}

async function downloadPatient({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<Patient> {
  const result = await fetch(`${AUTH_API_URL}/patients/${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
  const { patient } = await result.json();
  const patientInfo = await requestPatientFromFirestore(patient);

  Object.keys(patientInfo).forEach((key) => {
    patient[key] = patientInfo[key];
  });

  const pictureUri = await getFirebaseImage(`profile/${patient.id}.jpeg`);
  patient.avatar = pictureUri;
  patient.birthday = patient.birthday.replace(/-/g, '/');

  return patient;
}

async function updatePatient({
  headers,
  ...payload
}: ArgsWithHeaders<PatientPayload>): Promise<void> {
  const result = await fetch(`${AUTH_API_URL}/patients/${payload.id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify(payload),
  });

  if (!result.ok) {
    const error = await result.json();
    throw Error(error);
  }
}

async function downloadPatientReport({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<string> {
  const result = await fetch(
    `${AUTH_API_URL}/patients/${payload.id}/report/lifestyle`,
    {
      headers,
      method: 'GET',
    },
  );

  const data = await result.blob();
  if (!data) {
    const error = await result.json();
    throw Error(error);
  }
  const fileURL = URL.createObjectURL(data);
  return fileURL;
}

async function downloadPatientFiles({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<string> {
  const result = await fetch(`${AUTH_API_URL}/patients/${payload.id}/files`, {
    headers,
    method: 'GET',
  });

  return result.json();
}

async function downloadVisitHistory({
  headers,
  id,
  ...paginationPayload
}: ArgsWithHeaders<AppointmentId & PaginationPayload>): Promise<
  Paginated<Booking>
> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings/patient-general-visit-history/${id}${appendPaginationParams(
      paginationPayload,
    )}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('There was an error fetching the visit history');
  }

  const bookings = await result.json();

  return {
    data: bookings.bookings,
    count: bookings.count,
  };
}

async function downloadPublicVisitHistory({
  headers,
  id,
  search,
  offset,
  limit,
}: ArgsWithHeaders<PublicVisitHistoryPayload>): Promise<
  Paginated<VisitHistoryBookingPublicInList>
> {
  const result = await fetch(
    `${AUTH_API_URL}/bookings-public/patient-public-visit-history/${id}?limit=${limit}&offset=${offset}&search=${search}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('There was an error fetching the visit history');
  }

  const response = await result.json();

  return {
    data: response.bookings,
    count: response.count,
  };
}

async function downloadPatientFamilyHistory({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<any> {
  const result = await fetch(
    `${AUTH_API_URL}/patients/familyhistory/?relativeId=${payload.id}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('There was an error fetching the visit history');
  }
  return (await result.json()).familyhistory;
}

async function downloadPatientMedicalHistory({
  headers,
  ...payload
}: ArgsWithHeaders<PatientPayload>): Promise<any> {
  const sections = ['conditions', 'procedures', 'medications', 'allergies'];
  const data = await Promise.all(
    sections.map(
      async (section) =>
        await requestSectionFromFirestore(payload as Patient, section),
    ),
  );
  return data;
}

async function downloadPatientLifestyle({
  headers,
  ...payload
}: ArgsWithHeaders<PatientId>): Promise<any[]> {
  const result = await fetch(
    `${AUTH_API_URL}/patients/mylifestyle/?relativeId=${payload.id}`,
    {
      headers,
      method: 'GET',
    },
  );

  if (!result.ok) {
    throw new MMDError('There was an error fetching the visit history');
  }
  return (await result.json()).mylifestyles;
}

const requestUpdatePatientWatcher = createSingleEventSaga<
  Patient,
  Patient,
  MyAction<Patient>
>({
  takeEvery: Types.REQUEST_UPDATE_PATIENT,
  loadingAction: Creators.loadingPatients,
  commitAction: Creators.commitPatient,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: updatePatient,
  beforeAction: putAuthInfoInArgs,
  // eslint-disable-next-line require-yield
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<Patient>,
  ): SagaIteratorToolbox<any> {
    return args;
  },
});

const requestPatientsWatcher = createSingleEventSaga<
  Patient[],
  Patient[],
  MyAction<Patient[]>
>({
  takeEvery: (action) =>
    onRoute('/patients')(action) || action.type === Types.REQUEST_PATIENTS,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPatients,
  successAction: Creators.successPatients,
  errorAction: ErrorActions.setError,
  action: downloadPatients,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientWatcher = createSingleEventSaga<
  Patient,
  Patient,
  MyAction<Patient>
>({
  takeEvery: onRoute('/patients/:id') && Types.REQUEST_PATIENT,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPatient,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatient,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientReportWatcher = createSingleEventSaga<
  string,
  Patient[],
  MyAction<string>
>({
  takeEvery: Types.REQUEST_PATIENT_REPORT,
  loadingAction: Creators.loadingPatientReport,
  commitAction: Creators.commitPatientReport,
  successAction: Creators.successPatients,
  errorAction: ErrorActions.setError,
  action: downloadPatientReport,
  beforeAction: putAuthInfoInArgs,
});

const requestPatientFilesWatcher = createSingleEventSaga<
  string,
  (File | Patient)[],
  MyAction<string>
>({
  takeEvery: Types.REQUEST_PATIENT_FILES,
  loadingAction: Creators.loadingPatientFiles,
  commitAction: Creators.commitPatientFiles,
  successAction: Creators.successPatients,
  errorAction: ErrorActions.setError,
  action: downloadPatientFiles,
  beforeAction: putAuthInfoInArgs,
});

const requestAppointmentVisitHistoryWatcher = createSingleEventSaga<
  string,
  Booking[],
  MyAction<string>
>({
  takeEvery: Types.REQUEST_PATIENT_VISIT_HISTORY,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitPatientVisitHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadVisitHistory,
  beforeAction: putAuthInfoInArgs,
});

const requestPublicVisitHistoryWatcher = createSingleEventSaga<
  PublicVisitHistoryPayload,
  Paginated<VisitHistoryBookingPublicInList>,
  MyAction<PublicVisitHistoryPayload>
>({
  takeEvery: Types.REQUEST_PATIENT_PUBLIC_VISIT_HISTORY,
  loadingAction: noOpAction,
  commitAction: Creators.commitPatientPublicVisitHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPublicVisitHistory,
  beforeAction: putAuthInfoInArgs,
});

const requestAppointmentFamilyHistoryWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_PATIENT_VISIT_HISTORY,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointmentFamilyHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatientFamilyHistory,
  beforeAction: putAuthInfoInArgs,
});

const requestAppointmentMedicalHistoryWatcher = createSingleEventSaga<
  object,
  any,
  MyAction<object>
>({
  takeEvery: Types.REQUEST_APPOINTMENT_MEDICAL_HISTORY,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointmentMedicalHistory,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatientMedicalHistory,
  beforeAction: function* ({
    headers,
    ...payload
  }: ArgsWithHeaders<PatientId>): SagaIteratorToolbox<any> {
    // @ts-ignore
    const patient: Patient = yield select(patientSelector, payload.id);

    return {
      ...headers,
      ...patient,
    };
  },
});

const requestAppointmentLifestyleWatcher = createSingleEventSaga<
  object | string,
  any[],
  MyAction<object>
>({
  takeEvery: Types.REQUEST_APPOINTMENT_LIFESTYLE,
  loadingAction: LoadingActions.setLoading,
  commitAction: Creators.commitAppointmentLifestyle,
  successAction: noOpAction,
  errorAction: ErrorActions.setError,
  action: downloadPatientLifestyle,
  beforeAction: putAuthInfoInArgs,
});

export const patientsSagas = [
  requestPatientWatcher,
  requestPatientsWatcher,
  requestPatientFilesWatcher,
  requestUpdatePatientWatcher,
  requestPatientReportWatcher,
  requestPublicVisitHistoryWatcher,
  requestAppointmentLifestyleWatcher,
  requestAppointmentVisitHistoryWatcher,
  requestAppointmentFamilyHistoryWatcher,
  requestAppointmentMedicalHistoryWatcher,
];
